body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(139, 172, 233);
    background: linear-gradient(
        188deg,
        rgb(130, 130, 130) 0%,
        rgb(79, 79, 79) 0%,
        rgb(55, 55, 55) 50%,
        rgb(0, 0, 0) 100%
    );
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
