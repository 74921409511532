.App {
    text-align: center;
    color: white;
}

.fullPage {
    height: 100vh;
    width: 100vw;
}

.center {
    justify-content: center;
    align-items: center;
}

.x-center {
    justify-content: center;
}

.y-top {
    align-items: flex-start;
}

.y-center {
    align-items: center;
}

.flex-direction-column {
    flex-direction: column;
}

.flex {
    display: flex;
}

.container {
    width: 90vw;
    height: 80vh;
}

.y-scroll {
    overflow-x: auto;
}

.bg-gray {
    background-color: #fdfdfd;
}

.border-round {
    border-radius: 8px;
}

.buttons {
    margin: 10px;
}

.button {
    transition-duration: 0.3s;
    background-color: #fdfdfd;
    border: none;
    color: black;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    border-radius: 8px;
    width: 150px;
    height: 40px;
}

.button.small {
    background-color: #dcdcdc;
    color: black;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    border-radius: 8px;
    width: 100px;
    height: 35px;
}

.white {
    color: #fdfdfd;
}

.button:hover {
    background-color: #000000;
    color: rgb(255, 255, 255);
}

.icon {
    height: 15px;
    margin-right: 4%;
}

.w-full {
    width: 90vw;
}

h2 {
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    max-width: 500px;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    z-index: 10000;
    opacity: 0;
    animation: modalOpen 0.3s forwards;
}

@keyframes modalOpen {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal.closed {
    opacity: 0;
    transform: translateY(-50%) scale(0.8);
    animation: modalClose 0.3s forwards;
}

@keyframes modalClose {
    from {
        opacity: 1;
        /* transform: translateY(-50%) scale(1); */
    }
    to {
        opacity: 0;
        /* transform: translateY(-50%) scale(0.8); */
    }
}

.modal.open {
    opacity: 1;
    transform: translateY(-50%) scale(1);
}

.black {
    color: black;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.m-15 {
    margin: 15px;
}

.gap-15 {
    gap: 15px;
}

input {
    width: 90%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.w-100 {
    width: 100%;
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #dcdcdc;
    color: black;
    width: 70vw;
    max-width: 500px;
    border-radius: 8px;
    padding: 3%;
}

.error {
    color: red;
    font-size: 15px;
    margin: 0;
    padding: 0;
}

.bg-white {
    background-color: white;
}

.logoutButton {
    background-color: #fdfdfd;
    border: none;
    color: black;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    border-radius: 8px;
    width: 150px;
    height: 40px;
}

.space-between {
    justify-content: space-between;
}

.margin-left-auto {
    margin-left: auto;
}

.utente {
    margin: 10px;
}

.grid {
    display: grid;
    padding: 2%;
    grid-template-columns: repeat(
        5,
        1fr
    ); /* Adjust the number of columns as needed */
}

.secondGrid {
    display: grid;
    padding: 2%;
    gap: 3px;
    grid-template-columns: repeat(
        2,
        1fr
    ); /* Adjust the number of columns as needed */
    gap: 5px 25px;
}

.paid {
    width: 70%;
    background-color: #42e378;
    border-radius: 8px;
}

.toPay {
    width: 70%;
    background-color: #d76060;
    border-radius: 8px;
}

.grid-header {
    display: flex;
    justify-content: center;
    background-color: #4a4a4a;
    padding: 10px;
}

.grid-header-2 {
    font-weight: bold;
    border-bottom: 1px solid #999999; /* Add a straight bottom border */
}

.grid-cell-2 {
    display: flex;
    /* border-bottom: 1px solid #ccc; Add a straight bottom border */

}

.grid-cell {
    height: 25px;
    display: flex;
    justify-content: center;
    padding: 10px;
    color: #000000;
    border-bottom: 1px solid #ccc; /* Add a straight bottom border */
}

.grid-cell:nth-child(2),
.grid-cell:nth-child(4) {
    text-align: center;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
}

.deleteBtn {
    background-color: #d76060;
    border: none;
    color: black;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    border-radius: 4px;
    height: 25px;
    width: 100px;
}

.paidBtn {
    background-color: #78e89d;
    border: none;
    color: black;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    border-radius: 4px;
    height: 25px;
    width: 100px;
}

.gap-5 {
    gap: 5px;
}

.deleteBtn:hover {
    background-color: #d84141;
    color: rgb(0, 0, 0);
    transform: scale(1.1);
}
.paidBtn:hover {
    background-color: #42e378;
    color: rgb(0, 0, 0);
    transform: scale(1.1);
}

.expenseCard {
    flex-direction: column;
    justify-content: center;
    background-color: #dcdcdc;
    color: black;
    width: 70vw;
    max-width: 450px;
    border-radius: 8px;
    margin: 5%;
}

/* Create media query to resize grid for smathphone */
@media (max-width: 630px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .paid {
        width: 80%;
        border-radius: 4px;
        padding: 4px;
    }
    .toPay {
        width: 80%;
        border-radius: 4px;
        padding: 4px;
    }
    .grid-cell {
        padding: 12px;
    }
}
